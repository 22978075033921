
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import variables from "@/router/api";
// import SendClaimNotification from "@/components/modals/SendClaimNotification.vue";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    // SendClaimNotification
  },
  data() {
    return { passwordFieldType: "password", showPassword: false };
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      emailAddress: Yup.string()
        .email()
        .required()
        .label("Email"),
      password: Yup.string()
        .min(8)
        .required()
        .label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then(() => {
          variables.toastAlert("Sign in was successful", "success");

          // Redirect to page after successfully login
          const user = store.getters.currentUser;

          // Page redirects for Individual Client
          if (user.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
            if (user.cart.count > 0) {
              router.push({ name: "insurance-packages-cart" });
            } else {
              if (user.policyCount == 0) {
                router.push({ name: "insurance-packages" });
              } else {
                router.push({ name: "dashboard" });
              }
            }
          } else if (user.role == variables.COOPERATIVE_ADMIN_USER_ROLE) {
            if (user.cart.count > 0) {
              router.push({
                name: "cooperative-admin-insurance-packages-cart",
              });
            } else {
              router.push({ name: "cooperative-admin-dashboard" });
            }
          } else if (user.role == variables.BROKER_USER_ROLE) {
            router.push({ name: "broker-client-listing" });
          } else if (user.role == variables.FINANCE_USER_ROLE) {
            router.push({ name: "finance-dashboard" });
          } else if (user.role == variables.UNDERWRITER_USER_ROLE) {
            router.push({ name: "underwriter-dashboard" });
          } else if (
            user.role == variables.ADMIN_USER_ROLE ||
            user.role == variables.SUPER_ADMIN_USER_ROLE
          ) {
            router.push({ name: "admin-dashboard" });
          } else {
            router.push({ name: "dashboard" });
          }
          //
        })
        .catch(() => {
          if (store.getters.getErrors == variables.EXPIRED_JWT_TOKEN) {
            window.location.reload();
          } else {
            variables.toastAlert(store.getters.getErrors, "error");
          }
        });
      // Dummy delay
      setTimeout(() => {
        //Deactivate indicator
        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
  created() {
    document.title = "Sign In | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.showPassword = !this.showPassword;
    },
  },
});
